import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import axios from 'axios';

const Khidmatna = ({ images, activePrev}) => {

    const [siteData, setData] = useState([]);

    useEffect(() => {
        
        const fetchData = async () => {
            const data = await axios.get('https://admin.edraakcm.sa/api/service')
            setData(data.data)
        }
        fetchData();
    }, [])

    return (
        <section>
            <Link to="/Home" className="btn btn-slider animate__animated animate__fadeInRight animate__delay-1s" onClick={() => activePrev()}><img src={images.sliderButton} alt="sliderButton" /></Link>
            <div className="container">
            <div className="bg-img silver animate__animated animate__delay-1s animate__fadeInUp "></div>
                {
                siteData.map( item => 
                (
                    <div className="row" key={item.id} >
                        <div className="col-md-6">
                            <div className="text-right st-50 parallex">
                                <img src={item.side_image.url} className="img-fluid  animate__animated animate__fadeInRight animate__delay-1s top-60" alt="bulb" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className="animate__animated animate__fadeInLeft animate__delay-1s"><img src={images.headingIcon} alt="headingIcon" />&nbsp;{item.main_heading}</h3>
                            <div className="body-content animate__animated animate__fadeInDown animate__delay-1s">
                                <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                            </div>
                            <div className="text-center mt-5 animate__animated animate__fadeInLeft animate__delay-1s">
                                <img src={images.notepad} width="200" className="img-fluid" />
                                <div>
                                <div>
                                    <a target='_blank' href={encodeURI("/admin/storage/app/public/"+item.document.id+"/"+item.document.file_name+"")} className="btn btn-black sm">تحميل</a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Khidmatna
