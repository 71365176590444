import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert';
import * as scripts from '../assets/js/scripts';

const Atsalbana = ({ images, activePrev }) => {

    const[first_name, setFirstName] = useState('');
    const[last_name, setLastName] = useState('');
    const[city, setCity] = useState('');
    const[phone, setPhone] = useState('');
    const[email, setEmail] = useState('');

    // const [show, setShow] = useState(true);

    // useEffect(() => {
        
    //     return () => {
    //         $.ajaxSetup({
    //             headers: {
    //                 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //             }
    //         });
    //     }
    // }, [])

    const [siteData, setData] = useState([]);

    useEffect(() => {

        const contactform = async () =>{
       let getcon = await axios.get('https://admin.edraakcm.sa/api/contactpage')
        console.log(getcon);
        setData(getcon.data);
    }
    contactform();
        // .then(res => res.json())
        // .then(
        //     (result) => {
        //         setData(result)
        //     },
        // )
        $('.first_name, .last_name, .city').keyup(function() {
            
            if ($(this).val().length === 0) {
                $(this).removeClass('border-gold');
                $(this).addClass('border-red');
                $(this).val($.trim($(this).val()));
                $('.length-error').removeClass('d-none');
                $('.btn-form').addClass('disabled');
            }
            else if($(this).val().length > 2 ) {
                $(this).removeClass('border-red');
                $(this).addClass('border-gold');
                $('.length-error').addClass('d-none');
                $('.btn-form').removeClass('disabled');
            }
            else {
                $(this).val($.trim($(this).val()));
                $(this).removeClass('border-gold');
                $(this).addClass('border-red');
                $('.length-error').removeClass('d-none');
            }
            
        })

        $('form input').keyup(function() {
            
            if($('#first_name').val().length != 0 && $('#last_name').val().length != 0 && $('#city').val().length != 0 && $('#phone').val().length != 0 && $('#email').val().length != 0) {
                $('.btn-form').removeClass('disabled');
            }
            else {
                $('.btn-form').addClass('disabled');
            }
        })
        
    }, [])
    
    const submitData = (e) => {
        e.preventDefault();
        
        if(!first_name && !last_name && !city && !phone && !email) {
            $('.form-control').addClass('border-red');
        }
        else {
            swal({
                title: "تم ارسال طلبكم بنجاح",
                icon: "success",
              });
            setFirstName('');
            setLastName('');
            setCity('');
            setPhone('');
            setEmail('');
            axios.post('https://admin.edraakcm.sa/api/contacts', {
                first_name,
                last_name,
                city,
                phone,
                email
            })
            .then( 
                response => console.log(JSON.stringify(response.data))
                
                )
            .catch( error => {
                    console.log(error.response.data);
                });    
        }
        
    }

    $('.email').keyup(function(){
        if($(this).val().length == 0) {
            $('.btn-form').addClass('disabled');
        }
        var email = $(this).val();
        
        validateEmail(email)
     })

     function validateEmail(email) {
         var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
         if( !emailReg.test( email ) || $('.email').val().length == 0 && $('form input').val().length === 0) {
             $('.email-error').removeClass('d-none');
             $('.btn-form').addClass('disabled');
         } else {
             $('.email-error').addClass('d-none')
         }
     }

    //  $('.first_name, .last_name, .city, .phone, .email').change(function(){
    //     if ($(this).val().length == 0) {
    //         $(this).addClass('border-red');
    //         $('.btn-form').addClass('disabled')
    //     }
    // })

    $('.phone').change(function(){
        if ($(this).val().length == 1) {
            $(this).addClass('border-red');
        }
    })

    const inpNum = (e) => {
    
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^[0-9]+$/))
          e.preventDefault();
          
          
      }

    return (
        
        <section className="contact-form">
            <Link className="btn btn-slider animate__animated animate__fadeInRight animate__delay-1s" to="/Alehkaam" onClick={() => activePrev()}><img src={images.sliderButton} alt="sliderButton" /></Link>
            <div className="container">
        <div className="bg-img silver animate__animated animate__delay-1s animate__fadeInUp"></div>
                <div className="row pb-5">
                    <div className="col-md-6">
                    {
                        siteData.map( item => (
                        <div key={item.id} className="text-right st-90">
                            <img src={item.side_image.url} width="470" className="img-fluid animate__animated animate__fadeInRight animate__delay-1s parallex" alt="airMail" />
                        </div>
                        ))
                    }
                    </div>
                    <div className="col-md-6">
                        <div className="heading-extend">
                            <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;اتصل بنا</h3>                        
                        </div>
                        <p>نفتخــر نحــن شــركة ادراك بتقــديم خدمــات الإستشـارات الإداريـة والفنيـة للقطاعيـن العــام .والخاص، ممــا يســهم في بنــاء وازدهــار اقتصــاد المملكــة العربيــة السـعودية</p>
                        <form className="row gy-4 gx-5" method="post" onSubmit={e => {e.preventDefault()}}>
                            <div className="col-md-6">
                                <label className="form-label">الاسم *</label>
                                <input type="text" id="first_name" className={`form-control first_name ${first_name && 'border-gold'}`} placeholder="اسمك هنا .." value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">الجهة *</label>
                                <input type="text" id="last_name" className={`form-control last_name ${last_name && 'border-gold'}`} placeholder="اسم الجهه .." value={last_name} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">المدينة *</label>
                                <input type="text" id="city" className={`form-control city ${city && 'border-gold'}`} placeholder="المدينة من هنا .." value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">الجوال *</label>
                                <input type="number" id="phone" pattern="\d*" maxLength="13" className={`form-control phone ${phone && 'border-gold'}`} onKeyPress={inpNum} placeholder="009665XXXXXXXX" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">البريد الالكتروني *</label>
                                <span className='email-error d-none'>البريد الإلكتروني غير صحيح</span>
                                <input type="email" required id="email" className={`form-control email ${email && 'border-gold'}`} placeholder="البريد الالكتروني من هنا .." value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <span className="length-error d-none">*الرجاء إدخال 3 أحرف على الأقل</span>
                            <div className="col-12">
                            <button className="btn btn-black btn-form mx-3 with-arrow disabled" type="submit" onClick={submitData}>ارسال</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Atsalbana
