import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import Slider from "react-slick";

const Almutashareen = ({ images }) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        
        responsive: [

            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                // arrows: false
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
      };

    const [siteData, setData] = useState([]);
    const [consultants, setConsultants] = useState([]);
    const [tab, setTab] = useState([]);
    const [departments, setDepartment] = useState([]);
    
    const urlConsultants= 'https://admin.edraakcm.sa/api/consultants';

    const urlDepartments = 'https://admin.edraakcm.sa/api/getdepartment';

    useEffect(() => {
        
        fetch('https://admin.edraakcm.sa/api/consultantspages')
        .then(res => res.json())
        .then(
            (result) => {
                setData(result)
            },
        )
    }, [])
    
    useEffect(() => {
        
        fetch('https://admin.edraakcm.sa/api/getdepartment')
        .then(res => res.json())
        .then(
            (result) => {
                setDepartment(result)
                console.log('res', result)
            },
        )
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const users_data = await axios(urlConsultants);
            setConsultants(users_data);
            users_data.data.map(items=>{
                console.log('consultants', items.department)
                // if (tab.includes(items.department) === false) setTab([...tab, items.department]);
                tab.push(items.department)
            console.log(tab)
            })
            console.log('tab',tab)
        };
        fetchData();
        
    }, [setConsultants])

      
    return (
        <section className="slick_slider">
            <Link to="/Home" className="btn btn-slider animate__animated animate__fadeInRight animate__delay-1s"><img src={images.sliderButton} alt="sliderButton" /></Link>
            <div className="container">
            <div className="bg-img silver animate__animated animate__delay-1s animate__fadeInUp "></div>
                {
                siteData.map(item => 
                (    
                <div className="row pb-5" key={item.id}>
                    <div className="col-md-6">
                        <div className="text-right st-50">
                            <img src={item.side_image.url} width="450" className="img-fluid  animate__animated animate__fadeInRight animate__delay-1s" alt="airPods" />
                        </div>
                    </div>
                    <div className="col-md-6 animate__animated animate__fadeIn">
                        <div className="heading-extend">
                            <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;{item.main_heading}<img src={images.goldArrow} className='arrow-gold' alt="headingIcon" /><span className="animate__animated animate__fadeIn animate__delay-1s">{item.heading_2}</span></h3>
                        </div>
                        <div dangerouslySetInnerHTML={ {__html: item.description} } />
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                {
                                    (departments.length !== 0) &&
                                    departments.map((data, index) => (
                                    <button key={index} className={`nav-link ${index === 0 && 'active'}`} id={"nav-"+index+"-tab"} data-bs-toggle="tab" data-bs-target={"#nav-"+index} type="button" role="tab" aria-selected="false">{data.main_heading}</button>
                                    ))
                                }
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            
                            { 
                                (departments.length !== 0) &&
                                departments.map((data, index) => (
                                <div className={`tab-pane fade ${index === 0 && 'show active'}`} key={index} id={"nav-"+index} role="tabpanel">
                                    {/* {consultant.name} */}
                            <Slider {...settings}>
                            {
                                    (consultants.length !== 0)
                                ?
                                consultants.data.map(consultant => (
                                    // item.map(consultant => (
                                        
                                    consultant.department == data.id
                                    &&
                                    // <div key={consultant.id} id={data.id} data-id={consultant.department}>
                                        <div className="card">
                                            <img src={consultant.image.url ? consultant.image.url : ''} className="card-img-top rounded-circle" alt="" />
                                            <div className="card-body">
                                                <h6 className="card-title">{consultant.name}</h6>
                                                <p className="card-text">{consultant.designation}</p>
                                            </div>
                                        </div>
                                    // </div>
                                    // ))
                                    ))
                                    : <h1>Sorry info not found</h1>
                            }
                            </Slider>
                            </div>))}
                        {/* <div className="tab-pane fade" id="nav-second" role="tabpanel">
                            <Slider {...settings}>
                            {
                                    (consultants.length !== 0)
                                ?
                                consultants.data.map(consultant => (
                                    // item.map(consultant => (
                                        
                                    consultant.department == 2
                                    &&
                                    <div key={consultant.id}>
                                        <div className="card">
                                            
                                            <img src={consultant.image.url ? consultant.image.url : ''} className="card-img-top rounded-circle" alt="" />
                                            <div className="card-body">
                                                <h6 className="card-title">{consultant.name}</h6>
                                                <p className="card-text">{consultant.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                    // ))
                                    ))
                                    : <h1>Sorry info not found</h1>
                            }
                            </Slider>
                        </div>
                        <div className="tab-pane fade" id="nav-third" role="tabpanel">
                            <Slider {...settings}>
                            {
                                    (consultants.length !== 0)
                                ?
                                consultants.data.map(consultant => (

                                    // item.map(consultant => (
                                    consultant.department == 3
                                    &&
                                    <div key={consultant.id}>
                                        <div className="card">
                                            
                                            <img src={consultant.image.url ? consultant.image.url : ''} className="card-img-top rounded-circle" alt="" />
                                            <div className="card-body">
                                                <h6 className="card-title">{consultant.name}</h6>
                                                <p className="card-text">{consultant.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                    // ))
                                    ))
                                    : <h1>Sorry info not found</h1>
                            }
                            </Slider>
                        </div>
                        <div className="tab-pane fade" id="nav-fourth" role="tabpanel">
                            <Slider {...settings}>
                            {
                                    (consultants.length !== 0)
                                ?
                                consultants.data.map(consultant => (
                                    // item.map(consultant => (

                                    consultant.department == 4
                                    &&
                                    <div key={consultant.id}>
                                        <div className="card">
                                            <img src={consultant.image.url ? consultant.image.url : ''} className="card-img-top rounded-circle" alt="" />
                                            <div className="card-body">
                                                <h6 className="card-title">{consultant.name}</h6>
                                                <p className="card-text">{consultant.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                    // ))
                                    ))
                                    : <h1>Sorry info not found</h1>
                            }
                            </Slider>
                        </div>
                        <div className="tab-pane fade" id="nav-fifth" role="tabpanel">
                            <Slider {...settings}>
                            {
                                    (consultants.length !== 0)
                                ?
                                consultants.data.map(consultant => (
                                    // item.map(consultant => (

                                    consultant.department == 5
                                    &&
                                    <div key={consultant.id}>
                                        <div className="card">
                                            
                                            <img src={(consultant.image.url ? consultant.image.url : '')} className="card-img-top rounded-circle" alt="" />
                                            <div className="card-body">
                                                <h6 className="card-title">{consultant.name}</h6>
                                                <p className="card-text">{consultant.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                    // ))
                                    ))
                                    : <h1>Sorry info not found</h1>
                            }
                            </Slider>
                        </div> */}
                        </div>
                        
                    </div>
                </div>
                ))}
            </div>
        </section>
    )
}

export default Almutashareen
