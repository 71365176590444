import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import 'animate.css';
import 'jquery/dist/jquery';
import { $, event } from 'jquery';
import axios from 'axios';

import CountUp from 'react-countup';


const Home = ({ images, changeBackground,activePrev }) => {
    
    const [siteData, setData] = useState([]);
    const countUpRef = useRef(null);

 

    useEffect(() => {
        
        fetch('https://admin.edraakcm.sa/api/aboutus')
        .then(res => res.json())
        .then(
            (result) => {
            setData(result)
            },
        )

    }, [])



    return (
        <section className="section_home">
            <Link to="/" className="btn btn-slider animate__animated animate__fadeInRight animate__delay-1s" onClick={() => activePrev()}><img src={images.sliderButton} alt="sliderButton" /></Link>
            <div className="bg-img silver animate__animated animate__delay-1s animate__fadeInUp">
        </div>
            <div className="container">
            {siteData.map(item => 
            (
                <div className="row pb-4" key={item.id} >
                    <div className="col-md-6">
                        <div className="text-right parallex st-50 pb-5">
                            <img src={item.side_image.url} className="img-fluid animate__animated animate__fadeInRight animate__delay-1s" alt="building" />
                        </div>
                    </div>
                    
                        <div className="col-md-6">
                        
                            <h3 className="animate__animated animate__fadeInLeft animate__delay-1s"><img src={images.headingIcon} alt="headingIcon" />&nbsp;{item.main_heading}</h3>
                            <div className="body-content animate__animated animate__fadeInDown animate__delay-1s">
                            <div dangerouslySetInnerHTML={ {__html: item.description} } />
                            </div>
                            <h3 className="animate__animated animate__fadeInRight animate__delay-1s"><img src={images.headingIcon} alt="headingIcon" />&nbsp;{item.heading_2}</h3>
                            <div className="row justify-content-between animate__animated animate__fadeInUp animate__delay-1s justify">
                                
                                <div className="col-auto">
                                    <div className="numbers">
                                        <h4><CountUp end={item.counter_1} duration={1} delay={1.5} /></h4>
                                        <span>عدد الخدمات</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="numbers">
                                        <h4><CountUp end={item.counter_2} duration={3} delay={1.5} /></h4>
                                        <span>عدد المستشارين</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="numbers">
                                        <h4>+<CountUp end={item.counter_3} duration={3} delay={1.5} /></h4>
                                        <span>عدد العملاء</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="numbers">
                                        <h4>+<CountUp end={item.counter_4} duration={3} delay={1.5} /></h4>
                                        <span>عدد الاستشارات</span>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="mt-4 justify-content-between animate__animated animate__fadeInUp animate__delay-1s">
                                <Link to="/Almutashareen" className="btn btn-black left-arrow">المستشارين&emsp;<img src={images.leftArrow} alt="leftArrow" /></Link>  
                                <Link to="/Alamla" className="btn btn-black mx-3 left-arrow">العملاء&emsp;<img src={images.leftArrow} alt="leftArrow" /></Link>  
                            </div>
                        </div>
                    
                </div>
               ))}
            </div>
        </section>
    )
}

export default Home
