import React from 'react'

const Alraisabad = ({ images }) => {
    return (
        <section className="road-map section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main">
                            <img src={images.map }className="img-fluid" alt="" />
                            <div className="mid">
                                <img src={images.midImg} className="img-fluid mid-img" alt="" />
                                <span className="top">إدارك</span>
                                <span>حجم السوق</span>
                            </div>
                            <div className="box-1">
                                <img src={images.book} />
                                <div className="button">
                                    <button className="btn btn-brown">الملف التعريفي</button>
                                </div>
                            </div>
                            <div className="box-2">
                                <div className="content">
                                    <span>حجم مكانك</span>

                                    <img src={images.papers} />
                                </div>
                                
                                <div className="button">
                                <button to="/Almutashareen" className="btn btn-brown">قدم طلبك<img src={images.brownArrow} alt="brownArrow" /></button>
                                </div>
                            </div>
                            <div className="box-3">
                                <div>

                                <img src={images.diary} />
                                </div>
                                
                                <div className="button">
                                <button to="/Almutashareen" className="btn btn-brown">الشروط والأحكام</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className="text-center">
                <img src={images.notepad} />
                <div>
                    <button className="btn btn-brown">المستشارين</button>
                </div>
            </div> */}
        </section>
    )
}

export default Alraisabad
