import React, { useEffect, useState } from 'react'
import logo from '../../assets/icons/logo.svg';
import snapchat from '../../assets/icons/snapchat.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import twitter from '../../assets/icons/twitter.svg';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

const Navigation = ({ changeBackground }) => {

    const location = useLocation();

    const { pathname } = location;

    const splitLocation = pathname.split("/");

    const [socialLink, setSocialLink] = useState([]);
    
    useEffect(() => {
        const getSocialLinks = async () =>{
            let getcon = await axios.get('https://admin.edraakcm.sa/api/getsociallinks')
            const data = getcon.data;
            setSocialLink(data)
             console.log("social ",data)
         }
         getSocialLinks();
      }, []);
    

    return (
        <nav className="navbar navbar-expand-lg navbar-light hide-on-load">
            <div className="container-fluid align-items-end nav-container">
            <Link className="navbar-brand" to="/" onClick={() => changeBackground('pink')}><img src={logo} alt="logo" width="150" /></Link>
              {/* <a className="navbar-brand" href="">
                  
              </a> */}
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className={splitLocation[1] === "" ? "nav-item active" : "nav-item"}>
                        <Link to="/" className="nav-link" onClick={() => changeBackground('pink')}>الرئيسية</Link>
                    </li>
                    <li className={splitLocation[1] === "Home" ? "nav-item active" : "nav-item"}>
                    <Link to="/Home" onClick={() => changeBackground('silver')} className="nav-link">من نحن</Link>
                    </li>
                    <li className={splitLocation[1] === "khidmatna" ? "nav-item active" : "nav-item"}>
                        <Link  to="/khidmatna" className="nav-link" href="#" onClick={() => changeBackground('silver')}>الملف التعريفي</Link>
                    </li>
                    <li className={splitLocation[1] === "Alehkaam" ? "nav-item active" : "nav-item"}>
                        <Link to="Alehkaam" className="nav-link" href="#" onClick={() => changeBackground('silver')}>وصف الخدمات</Link>
                    </li>
                    <li className={splitLocation[1] === "Atsalbana" ? "nav-item active" : "nav-item"}>
                        <Link to="Atsalbana" className="nav-link" onClick={() => changeBackground('silver')}>اتصل بنا</Link>
                    </li>
                </ul>
                <div className="social_media me-auto">
                    {socialLink.map(item => 
                    (
                    <>
                    {
                        item.platform === "twitter" && 
                        <span><a href={item.link} target="_blank" ><img src={twitter} alt={item.platform} width="15"/></a></span>
                    }
                    {
                        item.platform === "linkedin" && 
                        <span><a href={item.link} target="_blank" ><img src={linkedin} alt={item.platform} width="15"/></a></span>
                    }
                    {
                        item.platform === "snapchat" && 
                        <span><a href={item.link} target="_blank" ><img src={snapchat} alt={item.platform} width="15"/></a></span>
                    }
                    </>
                    ))}
                </div>
              </div>
            </div>
        </nav>
    )
}

export default Navigation
