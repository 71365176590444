import React from 'react'

const Footer = ({ getYear }) => {
    return (
        <section className="footer-wrap pt-3 pb-3 hide-on-load animate__animated animate__bounce animate__backInUp animate__delay-1s">
            <footer>
                <div className="text-center">
                    <p>جميع الحقوق محفوظة لشركة إدراك @ {getYear}</p>
                </div>
            </footer>
        </section>
    )
}

export default Footer
