import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Alamla = ({ images }) => {

    const [siteData, setData] = useState([]);
    const [clients, setClients] = useState([]);

    const urlClients= 'https://admin.edraakcm.sa/api/customers';

    useEffect(() => {
        fetch('https://admin.edraakcm.sa/api/customerpages')
        .then(res => res.json())
        .then(
            (result) => {
                setData(result)
            },
        )
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const users_data = await axios(urlClients);
            setClients(users_data);
        };
        fetchData();
        
    }, [setClients])
    console.log(clients)
    
    return (
        <section>
            <Link to="/Home" className="btn btn-slider animate__animated animate__fadeInRight animate__delay-1s"><img src={images.sliderButton}alt="sliderButton" /></Link>
            <div className="container">
           
            <div className="bg-img silver animate__animated animate__delay-1s animate__fadeInUp "></div>
                {
                siteData.map( item => 
                (
                    <div className="row pb-5" key={item.id}>
                        <div className="col-md-6">
                            <div className="text-right st-80">
                                <img src={item.side_image.url} className="img-fluid  animate__animated animate__fadeInRight animate__delay-1s" width="470" alt="star" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="heading-extend">
                                <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;{item.main_heading}<img src={images.goldArrow} className='arrow-gold' alt="goldArrow" /><span className="animate__animated animate__fadeIn animate__delay-1s">{item.heading_2}</span></h3>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: item.description}} />
                            <div className="clients">
                                <div className="row justify-content-end animate__animated animate__fadeIn">
                                {
                                    (clients.length !== 0)
                                    ?
                                    clients.data.map(client => (
                                        // item.map(client => (
                                        <div className="col-md-2 col-3 mb-2" key={client.image.id}>
                                            <img  src={client.image.url} alt="client" />
                                        </div>
                                        // ))
                                    ))
                                    : <h1>Sorry info not found</h1>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                
            </div>
            
        </section>
    )

}

export default Alamla
