import React from 'react'
import { Link } from 'react-router-dom';

const Alraisa = ( { images } ) => {
  
    return (
        <>
        <section className="road-map section hide-on-load">
        <i class="fas fa-address-card"></i>
            <div className="container abc">
           
                <div className="row">
                    <div className="col-md-12">
                        <div className="main animate__animated animate__fadeInRight animate__delay-1s">
                            <img src={images.maps}className="img-fluid map-img" alt="" />
                            <div className="mid">
                                <span className="top dance-text">إدارك</span>
                                <div className="ellipeses">
                                    {/* <img src={images.midImg1} className="img-fluid mid-img rod1" alt="" /> */}
                                    <img src={images.midImg2} className="img-fluid mid-img rod3" alt="" />
                                    {/* <img src={images.midImg3} className="img-fluid mid-img rod3" alt="" /> */}
                                    <img src={images.midImg4} className="img-fluid mid-img rod4" alt="" />
                                    <img src={images.midImg5} className="img-fluid mid-img rod5" alt="" />
                                </div>
                                <span className="dance-text antiClock">حجم السوق</span>
                            </div>
                            <div className="box-1">
                                <div className="parallex">
                                    <img src={images.book} className="img-fluid" width="150" />
                                </div>
                                <div className="button">
                                    <Link to="/khidmatna" className="btn btn-black">الملف التعريفي</Link>
                                </div>
                            </div>
                            <div className="box-2">
                                <div className="content">
                                    <span className="dance-text">حجم مكانك</span>
                                    <div className=" parallex">
                                        <img src={images.papers} width="150" className="img-fluid" />
                                    </div>
                                </div>
                                
                                <div className="button">
                                <Link to="/AlraisatulAwal" className="btn btn-black left-arrow">قدم طلبك&emsp;<img src={images.leftArrow} alt="leftArrow" /></Link>
                                </div>
                            </div>
                            <div className="box-3">
                                <div className="parallex">
                                    <img src={images.diary} className="position-diary" width="140" />
                                </div>
                                
                                <div className="button">
                                <Link to="/Alehkaam" className="btn btn-black">وصف الخدمات</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className="text-center">
                <img src={images.notepad} />
                <div>
                    <button className="btn btn-black">المستشارين</button>
                </div>
            </div> */}

            
        </section>
            <div className="main-progress">
            <p className='progress text-center bg-transparent d-block'>0</p>  

            <div className="progress-bar bar-load" id="bar"></div>
        </div>
        
        <div className="bg-img hide-on-load pink animate__animated animate__delay-1s animate__fadeInUp animate_fast"></div>
        
        </>
    )
}

export default Alraisa
