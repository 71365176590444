import React from 'react'
import $, { event } from 'jquery';
import * as scripts from '../assets/js/scripts';
import { useEffect, useState, useRef } from 'react';

import { Link } from 'react-router-dom';
import axios, {  } from 'axios';
import MicRecorder from 'mic-recorder-to-mp3';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const AlraisatulAwal = ({ images }) => {
 
    const showOriginalForm = () => {
        $('.original-form').show();
        $('.form-recording').addClass('d-none');
        $('.form-writing').addClass('d-none');
    }

        const [basic_information, setBasicInformation] = useState('');
        const [first_name, setFirstName] = useState('');
        const [last_name, setLastName] = useState('');
        const [city, setCity] = useState('');
        const [phone, setPhone] = useState('');
        const [email, setEmail] = useState('');
        const [additional_details, setAdditionalDetails] = useState('');
        const [book_your_appointment, setAppointment] = useState('');
        const [text, setText] = useState('');
        const [date, setDate] = useState('');
        const [audio, setAudio] = useState();
        const [time, setLocalTime] = useState('');

        const recorder = useRef(null); //Recorder
        const audioPlayer = useRef(null); //Ref for HTML Audio tag
        const timer = useRef(null); //Ref for interval object
        const myTime = useRef(0); //Stores the value of time
        const displayTime = useRef(null); //Stores dom ref for div to be used to display time

        const [isRecording, setIsRecording] = useState(null);
        const [blobURL, setBlobUrl] = useState(null);
        const [play, setPlay] = useState(false);
        const Ref = useRef(null);
        const [rtimer, setrTimer] = useState('00:00');
        const [ptimer, setpTimer] = useState('00:00');
        const [dataLength, setDataLength] = useState({});

        const [hasAudio, setHasAudio] = useState(false);

    useEffect(() => {
        let mounted = true;
        // code to run on component mount
        $('input[type=radio]').click(function(){
            console.log($('input[type=radio]:checked').val())
     
            var option = $(this,':checked').val();
            if(option == 'تسجيل صوتي') {
                $(this).parents('form').find('.next').hide();
                $(this).parents('form').find('.recorder-btn').removeClass('d-none');
                $(this).parents('form').find('.notes-btn').addClass('d-none');
            }
            else if(option == 'كتابة نصية') {
                 $(this).parents('form').find('.next').hide();
                 $(this).parents('form').find('.recorder-btn').addClass('d-none');
                 $(this).parents('form').find('.notes-btn').removeClass('d-none');
            }
         })
     
        $('.form-check-label').click(function(){
            $(this).addClass('active');
            $(this).parent().prevAll().children('label').removeClass('active');
            $(this).parent().nextAll().children('label').removeClass('active');
            $(this).parents('form').find('.next').removeClass('disabled').addClass('active');
        })
         // Go to Voice Recording Page
        $('.recorder-btn').click(function(){
            $('.original-form').hide();
            $('.form-recording').removeClass('d-none');
        })

        // Show Writing Form
        $('.notes-btn').click(function(){
            $('.original-form').hide();
            $('.form-recording').addClass('d-none');
            $('.form-writing').removeClass('d-none');
        })

        // Show Last form
        $('.last-original .next').click(function(){
            $('.last-original').hide();
            $('.last-submit').removeClass('d-none');
        })

        // Delete Audio
        // recorder.current = new MicRecorder({ bitRate: 128 });
        // var moment = require('moment');
        // var m = new moment();
        // m.round(5, 'seconds')
        // console.log(m)

        $('.form-process .second .first_name, .form-process .second .last_name, .form-process .second .city').keyup(function(e) {
                if($(this).val().length > 2) {
                    $(this).addClass('border-gold');
                    $(this).removeClass('border-red');
                    $('.length-error').addClass('d-none');
                }
                else {
                    $(this).val($.trim($(this).val()));
                    $(this).addClass('border-red');
                    $(this).removeClass('border-gold');
                    $('.length-error').removeClass('d-none');
                }
                // $(this).removeClass('border-red');
        })

        $('.form-process .second input').keyup(function(e) {
            if($('.first_name').val().length!==0 && $('.last_name').val().length!==0 && $('.city').val().length!==0 && $('.phone').val().length!==0 && $('.email').val().length!==0) {
                $('.btn-form').removeClass('disabled');
            }
            else {
                $('.btn-form').addClass('disabled');
            }
        })

        $('.form-process .second .first_name, .form-process .second .last_name, .form-process .second .city, .form-process .second .phone, .form-process .second .email').keyup(function(){
            if ($(this).val().length === 0) {
                // $(this).removeClass('border-gold');
                $(this).addClass('border-red');
                console.log($('.form-process .second .phone').length)
            }
            else {
                $(this).addClass('border-gold');
            }
        })

        $('.form-process .second .phone').keyup(function(){
            if ($(this).val().length === 0) {
                $(this).addClass('border-red');
                $(this).removeClass('border-gold');
            }
            else if($(this).val().length > 0) {
                $(this).addClass('border-gold');
                $(this).removeClass('border-red');
            }
        })

        $('.form-process .second .email').keyup(function(){
           var email = $(this).val();
           validateEmail(email)
        })

        function validateEmail(email) {
            var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
            if( !emailReg.test( email ) || $('.email').val().length == 0) {
                $('.email-error').removeClass('d-none');

                $('.btn-form').addClass('disabled');
            } else {
                $('.email-error').addClass('d-none')
                $('.caution').removeClass('d-none');
                // $('.btn-form').removeClass('disabled');
            }
        }

        const getLength = async () =>{
            let getcon = await axios.get('https://admin.edraakcm.sa/api/getduration')
            const data = getcon.data[0];
           setDataLength(data)
         }
         getLength();

        recorder.current = new MicRecorder({ bitRate: 128 });
        $('.ask-voice').click(function(){
            navigator.mediaDevices.getUserMedia({ audio: true})
            .then(function (stream) {
                if (stream.getAudioTracks().length > 0){
                    setHasAudio(true)
                } else {
                }
            })
            .catch(function (error) { 
                setHasAudio(false)
            });
        })

        return () => mounted = false;

      }, [])

        const handleSubmit = async (e) => {
            // // e.preventDefault();
            const formData = new FormData();
            formData.append("basic_information", basic_information);
            formData.append("first_name", first_name);
            formData.append("last_name", last_name);
            formData.append("city", city);
            formData.append("phone", phone);
            formData.append("email", email);
            formData.append("additional_details", additional_details);
            formData.append("book_your_appointment", book_your_appointment);
            formData.append("text", text);
            formData.append("date", date);
            formData.append("audio", audio);
            formData.append("time", time);

            localStorage.setItem("basic_information", basic_information);
            localStorage.setItem("first_name", first_name);
            localStorage.setItem("last_name", last_name);
            localStorage.setItem("city", city);
            localStorage.setItem("book_your_appointment", book_your_appointment);
            localStorage.setItem("email", basic_information);
            localStorage.setItem("phone", phone);
            localStorage.setItem("date", date);
            localStorage.setItem("time", time);



            await fetch("https://admin.edraakcm.sa/api/requestform", {
                method: "POST",
                body: formData,
            })
            .then(response => console.log(JSON.stringify(response.data))
            )
            .catch( 
                error => {console.log(error.response.data)
                })
          }

        const setLocalDate = (e) => {
            setDate(e.format("YYYY-MM-DD"))
            $('.select-date input').addClass('border-gold');
            $('.select-date input').css('direction', 'ltr')
        }

        const setLTime = (e) => {
            setLocalTime(e.format("HH:mm:ss"));
            $('.select-time input').addClass('border-gold');
            $('.select-time input').css('direction', 'ltr')
        }
           
        var i = 0;
        var width;
        const animateBar = () => {
            if (i == 0) {
                i = 1;
                var elem = document.getElementById("bar");
                width = 1;
                window.id = setInterval(frame, 1000);
                function frame() {
                //   if (width >= 100) {
                //     clearInterval(id);
                //     i = 0;
      
                //   } else {
                    width++;
                    elem.style.width = width + "%";
                //   }
                }
            }
        }
        const startRecording = () => {
            onClickReset();
            $('.actions .stop').show();
            $('.recording-display').show();
            //start() returns a promise incase if audio is not blocked by browser
            // if(hasAudio){
                recorder.current.start().then(() => {
                    setIsRecording(true);
                    if (i == 0) {
                      i = 1;
                      var elem = document.getElementById("bar");
                      width = 1;
                      window.id = setInterval(frame, 1000);
                      function frame() {
                        if (width >= 100) {
                          clearInterval(window.id);
                          stopRecording();
                          i = 0;
                          // elem.style.width = 0 + "%";
                        } else {
                          width += (100 / Number(dataLength.audio_length));
                          console.log('width: ',width)
                          elem.style.width = width + "%";
                        }
                      }
                  }
                  });
            // }
            
            
        };
        const [downloadLink, setLink] = useState('');
        
        const stopRecording = () => {
            console.log($('#audio').currentTime)
            clearTimer(getDeadTime())
            $('.recording-display').hide();
            $('.actions .back, .actions .play, .actions .delete').removeClass('d-none');
            clearInterval(window.id);
            i = 0;
            recorder.current
              .stop()
              .getMp3()
              .then(([buffer, blob]) => {
                const file = new File(buffer, 'recorder-audio.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                  });
                  console.log('file', file)
                const newBlobUrl = URL.createObjectURL(blob); //generates url from blob
                setAudio(file)
                setBlobUrl(newBlobUrl); //refreshes the page
                console.log('blob:'+newBlobUrl)
                var Link = newBlobUrl;
                setLink(Link)
                setIsRecording(false);
              })
              .catch((e) => console.log(e));
          };

          useEffect(() => {
            let mounted = true;
            //start/stops timer depending on recording state
            if (isRecording) {
              startTimer();
            } else {
              stopTimer();
            }
            return () => mounted = false;
          }, [isRecording]);

        var pause = 1;
        const player = audioPlayer.current;
          const playPause = () => {
            $('.time-display').removeClass('d-none');
            if (i == 0) {
                i = 1;
                var elem = document.getElementById("progress");
                width =0;
                const playProgress = setInterval(frame, 1000);
                function frame() {
                  if (width >= 99 || pause === 0) {
                    clearInterval(playProgress);
                    i = 0;
      
                  } else {
                    console.log(time.current)
                    width += (100 / Math.floor(audioPlayer.current.duration));
                    console.log('width: ',width)
                    elem.style.width = width + "%";
                  }
                }
                
            }

            $('.recording-display').hide();
            $('.time-display').removeClass('d-none');
            var counter = 0
            const playTimer = setInterval(() => {
                counter = counter + 1;
                $('.time-display').text(counter);
                if(counter == myTime.current || pause === 0) {
                    clearInterval(playTimer)
                }
            }, 1000);
            // clearInterval(playTimer)
            //Handle play/pause of audio on click of play/pause button
            
            if (player.paused) {
              player.play();
            } else {
              player.pause();
              clearInterval(myTime.current)
              clearInterval(timer.current)
            }
            setPlay(!play);
            
          };

          $('.player-stop').click(function(){
            pause = 0;
            // setPlay(false)
            // player.pause();
            // clearInterval(myTime.current)
            // clearInterval(timer.current)
            // clearInterval(playTimer)
            // clearInterval(playProgress)
            // clearInterval(myTime.current)
            // clearInterval(timer.current)
            // setPlay(false)
      })

          const handlePrev = () => {
            //sets the playback time to 0 when clicked on previous button
            audioPlayer.current.time = 0;
          };
        
          const startTimer = () => {
            //sets interval that updates time on UI every second
            myTime.current = 0;
            timer.current = setInterval(() => {
                myTime.current = myTime.current + 1;
                // displayTime.current.innerText = myTime.current;
              if(myTime.current == 100) {
                  clearInterval(timer.current)
              }
              //Displays time by updating the DOM directly
            }, 1000);
          };
        
          const stopTimer = () => {
            clearInterval(timer.current);
          };

          const setTime = () => {
            if (audioPlayer.current) {
                // displayTime.current.innerText = Math.floor(audioPlayer.current.duration);
              
              //Displays time by updating the DOM directly
              //Note: Math.floor is used since audio time is in decimal and player only displays floor values
              //eg 3.86 will be displayed as 3 seconds in player
            }
          };

          const onClickReset = () => {
            clearTimer(getDeadTime());
        }

          const deleteAudio = () => {
            var elem = document.getElementById("progress");
            elem.style.width = 0;
            $('.original-form').show();
            $('.form-recording').addClass('d-none');
            $('.recording-display').hide();
            $('.time-display').addClass('d-none');
          }


    const [text_length, setTextLength] = useState(0);

    // $('.text-area').keyup(function() {
    //     if($('.text-area').val().length >=28) {
    //         setTextLength('abc');
    //     }
    //     else {
    //         setTextLength(text_length + 1);
    //     }
    // })
          
    let inputProps = { placeholder: 'اختر التاريخ' };
    let tplcaeHolder = { placeholder: 'اختر الوقت المناسب' };

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 * 60 * 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startMyTimer = (e) => {
        let { total, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
  
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the begining of the variable
            setrTimer(
                // (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {
        
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setrTimer((dataLength.audio_length/60)+':00');
  
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startMyTimer(e);
           
        }, 1000)
        Ref.current = id;
        console.log('ref', Ref.current)
    }

    const getDeadTime = () => {
        let deadline = new Date();
        var audio_length = dataLength.audio_length;
        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + Number(audio_length));
        console.log('audio', dataLength.audio_length)

        return deadline;
    }

    useEffect(() => {
        let mounted = true;
        clearTimer(getDeadTime());
        return () => mounted = false;
    }, []);

    // text area length
    $('.txt-area').keyup(function(){
        var length = $(this).val().length;
        if(length > 0){
            $('.txt-delete').removeClass('d-none');
        }
        else {
            $('.txt-delete').addClass('d-none');
            $('.length-error.text').removeClass('d-none');
        }
        if(length < 10){
            $('.third-next').addClass('disabled');
            $('.txt-area').removeClass('border-gold');
            $('.txt-area').addClass('border-red');
            $('.length-error.text').removeClass('d-none');
        }
        else {
            $('.third-next').removeClass('disabled');
            $('.txt-area').addClass('border-gold');
            $('.txt-area').removeClass('border-red');
            $('.length-error.text').addClass('d-none');
        }
        $('.txt-length').html(length+'/'+dataLength.text_length)
    })

    $('.txt-delete').click(function(){
        $('.txt-area').val('');
        $('.txt-length').html(0+'/'+dataLength.text_length);
        $('.txt-area').removeClass('border-gold');
        $('.txt-area').addClass('border-red');
        $('.length-error.text').removeClass('d-none');
        $('.third-next').addClass('disabled');
        $(this).addClass('d-none');
    })

    var max = dataLength.text_length;
    $('.txt-area').keypress(function(e) {

        if (e.which < 0x20) {
            // e.which < 0x20, then it's not a printable character
            // e.which === 0 - Not a character
            return;     // Do nothing
        }
        if (this.value.length == max) {
            e.preventDefault();
        } else if (this.value.length > max) {
            // Maximum exceeded
            this.value = this.value.substring(0, max);
        }
    });

    const inpNum = (e) => {
    
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^[0-9]+$/))
          e.preventDefault();
          
          
      }

      $('.radio-voice').click(function() {
        navigator.mediaDevices.getUserMedia({ audio: true})
        .then(function (stream) {
                if (stream.getAudioTracks().length > 0){
                    //code for when none of the devices are available
                } else {
                    // code for when both devices are available
                }
        })
        .catch(function (error) { 
            setHasAudio(true)
        });
      })

      $('.no-audio').click(function(){
        $('.audio-error').removeClass('d-none');
      })
  
      $('.yes-text').click(function() {
        $('.audio-error').addClass('d-none');
      })

    return (
        <>

        <section className="form-process">

            <div id="carouselExampleIndicators" className="carousel" data-wrap="false" data-pause="true">
                
                {/* <div className="carousel-indicators">                 
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div> */}
                <div className="carousel-inner">
                    {/* One */}
                    <div className="carousel-item first active animate__animated animate__fadeInLeft" data-bs-interval="100000">
                    
                        <Link to="/" className="btn btn-slider animate__animated animate__fadeInRight animate__delay-1s"><img src={images.sliderButton} alt="sliderButton" /></Link>
                        <div className="container bottom-with-50">
                                <div className="row custom-hit">
                                    <div className="col-md-6">
                                        <div className="text-right parallex set-position one">
                                            <LazyLoadImage  effect="opacity" src={images.one} className="img-fluid animate__animated animate__fadeInLeft animate__delay-1s animate__fast number" width="530" alt="secondForm" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="heading-extend">
                                            <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;البيانات الأساسية</h3>
                                        </div>
                                        <form className="row radio-form">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.buildingSmall} className="img-fluid building" alt="building" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">جهة حكومية أو خاصة</h6>
                                                                    <span>
                                                                    مساعدة الجهات في تقديم مجموعة من الخدمات و الحلول الإستشارية المبتكرة 
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" onChange={() => setBasicInformation('جهة حوكمية أو خاصة')} value="جهة حوكمية أو خاصة" />
                                                    
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.briefcase} alt="briefcase" className="img-fluid briefcase" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">الحصول على قائد</h6>
                                                                    <span>
                                                                    مساعدة عملائنا على تقديم الخدمات الاستشارية وإستقطاب القادة التنفيذيين القياديين
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" onChange={() => setBasicInformation('الحصول على قائد')} value="الحصول على قائد" />
                                                    
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios3">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.deskLamp} className="img-fluid lamp" alt="lamp" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">موظف على رأس العمل ؟</h6>
                                                                    <span>
                                                                    مساعدة عملائنا الموظفين إلى تقديم الاستشارات المهنية و العمالية
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" onChange={() => setBasicInformation('موظف على رأس العمل ؟')} value="موظف على رأس العمل ؟" />
                                                    
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios4">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.fileHolder} alt="fileHolder" className="img-fluid file-holder" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">فرد تبحث عن وظيفة ؟</h6>
                                                                    <span>
                                                                    مساعدة الباحثين عن العمل على تقديم جلسات استشارية افتراضيه للمقابلات الشخصية وصياغة وتطويرالسيرة الذاتية
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4"  onChange={() => setBasicInformation('فرد تبحث عن وظيفة ؟')} value="فرد تبحث عن وظيفة ؟" />
                                                </div>
                                                <button className="btn btn-black next btn-up left-arrow disabled" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">قدم طلبك&emsp;<img src={images.leftArrow} alt="leftArrow" /></button>
                                                <div className='pdf-link'>بتقديمك للطلب، فأنت مقر بموافقتك على  <a href="https://edraakcm.sa/admin/storage/app/public/terms/edraak-terms-conditions-2022.pdf" target="_blank">الشروط والأحكام </a></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="carousel-text animate__animated animate__fadeIn animate__delay-1s animate_fast">
                                    <p>البداية بدون <span className="mr-2">إدارك</span></p>
                                </div>
                                <div className="carousel-indicators animate__animated animate__fadeIn animate__delay-1s animate_faster">                 
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                        </div>
                    </div>
                    {/* Two */}
                    <div className="carousel-item second animate__animated animate__fadeInLeft animate__faster" data-bs-interval="100000">
                       
                        <button className="btn btn-slider" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"><img src={images.sliderButton} alt="sliderButton" /></button>
                        <div className="container bottom-with-50">
                            <div className="row custom-hit">
                                <div className="col-md-6">
                                    <div className="text-right parallex set-position">
                                        <img src={images.two} className="img-fluid animate__animated animate__fadeInLeft animate__delay-1s animate__fast number" width="490" alt="secondForm" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="heading-extend">
                                        <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;البيانات الأساسية</h3>
                                    </div>
                                    <form className="row gy-4 gx-5">
                                        <div className="col-md-6">
                                            <label className="form-label">الاسم *</label>
                                            <input type="text" required className="form-control first_name" value={first_name} onChange={(e) => setFirstName(e.target.value)} placeholder="اسمك هنا .." />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">الجهة *</label>
                                            <input type="text" className="form-control last_name" value={last_name} onChange={(e) => setLastName(e.target.value)} placeholder="اسم الجهه .." />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">المدينة *</label>
                                            <input type="text" className="form-control city" value={city} onChange={(e) => setCity(e.target.value)} placeholder="المدينة من هنا .." />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">الجوال *</label>
                                            <input type="number" className="form-control phone" pattern="\d*" maxLength="13" onKeyPress={inpNum} value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="009665XXXXXXXX" />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">البريد الالكتروني *</label>
                                            <span className='email-error d-none'>البريد الإلكتروني غير صحيح</span>
                                            <input type="email" className="form-control email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="البريد الالكتروني من هنا .." />
                                        </div>
                                        <div className='col-md-12'>
                                        <span className="length-error d-none">*الرجاء إدخال 3 أحرف على الأقل</span>
                                            <span className='caution d-none'>بالخطوة القادمة سيطلب منك تصريح لقبول استخدام المايكروفون في حال القبول ستتمكن من ارسال تفاصيل طلبك صوتياً في حال الرفض تستطيع تزويدنا بالتفاصيل نصياً.</span>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-black left-arrow p-14 disabled btn-form ask-voice" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">التالي&emsp;<img src={images.leftArrow} alt="leftArrow" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="carousel-text">
                                <p>بدايتك مع <span className="mr-2">إدارك</span></p>
                            </div>
                            <div className="carousel-indicators">                 
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className="active" aria-current="true" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            </div>
                        </div>
                    </div>
                    {/* Three */}
                    <div className="carousel-item third animate__animated animate__fadeInLeft animate__fast" data-bs-interval="100000">
                        
                        <button className="btn btn-slider" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"><img src={images.sliderButton} alt="sliderButton" /></button>
                        {/* <div className="bg-img formBg animate__animated animate__fadeInUp animate__faster"></div> */}
                        <div className="container bottom-with-50">
                            <div className="row custom-hit">
                                <div className="col-md-6">
                                    <div className="text-right parallex set-position">
                                        <img src={images.three} className="img-fluid animate__animated animate__fadeInLeft animate__delay-1s animate__fast number" width="500" alt="thirdForm" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="original-form">
                                        <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;تفاصيل إضافية</h3>
                                        <p>كلما كانت التفاصيل واضحه ومباشرة كلما كانت مهمة إعتماد الاجتماعات أسهل وأيسر فبالتالي وفرنا أكثر من طريقة لتقديم التفاصيل.</p>
                                        <form className="row radio-form">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <label className='form-check-label no-audio' htmlFor="exampleRadios5">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.recorder} className="img-fluid recorder" alt="recorder" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">تسجيل صوتي</h6>
                                                                    <span>
                                                                    مدة التسجيل كحد أقصى 120 ثانية.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {!hasAudio && <div className='col-md-12'><div className='audio-error d-none'>منفذ التسجيل قد لايكون متوفر لديكم أو يتم استخدامه في تسجيل آخر، يمكنك ارسال رسالة نصية.</div></div>}
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" onChange={() => setAdditionalDetails('Audio_recording')} value="تسجيل صوتي" />
                                                    
                                                </div>
                                            
                                                <div className='form-check'>
                                                    <label className="form-check-label yes-text radio-text" htmlFor="exampleRadios6">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.pencils} className="img-fluid pencils" alt="pencils" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">كتابة نصية</h6>
                                                                    <span>
                                                                    عدد الأحرف المسموح به 280 حرف كحد أقصى.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios6" onChange={() => setAdditionalDetails('كتابة نصية')} value="كتابة نصية" />
                                                    
                                                </div>
                                                <button className="btn btn-black next arrow-down p-14 disabled" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">اختيار&emsp;<img src={images.leftArrow} alt="leftArrow" /></button>
                                                <button className="btn btn-black recorder-btn p-14 d-none" onClick={startRecording} disabled={!hasAudio} type="button"></button>
                                                <button className="btn btn-black notes-btn p-14 d-none" type="button"></button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="form-recording d-none">
                                        <div className="heading-extend">
                                            <h3>تفاصيل إضافية<img src={images.goldArrow} className='arrow-gold' alt="goldArrow" /><span>تسجيل صوتي</span></h3>
                                        </div>
                                        <p>كلما كانت التفاصيل واضحه ومباشرة كلما كانت مهمة إعتماد الاجتماعات أسهل وأيسر فبالتالي وفرنا أكثر من طريقة لتقديم التفاصيل.</p>
                                        <div className="voice-recording">
                                            <img src={images.voice} alt="voice-recording" />
                                            <div className="voice-recorder">
                                                <div className={`${!isRecording ? 'playing': 'recording'} ${!play ? 'progress-bar-brown' : 'progress-bar-black'}`} id={`${!isRecording ? 'progress': 'bar'}`}></div>
                                            </div>
                                            <div className="play-time">
                                                <span className='recording-display'>{ rtimer }</span>
                                                {/* <span ref={displayTime} className='recording-display'>{timer.current}</span> */}
                                                <span className="time-display d-none"></span>
                                            </div>
                                            <div className="actions">
                                                {  isRecording 
                                                    &&
                                                  
                                                    <button className="btn stop drop-shadow p-14" onClick={stopRecording} disabled={!isRecording}></button>
                                                    
                                                }
                                                {
                                                    !isRecording
                                                    &&
                                                    <>
                                                    <button className="btn btn-brown back drop-shadow"  data-bs-target="#carouselExampleIndicators" data-bs-slide="next">التالي&emsp;<img src={images.leftArrow} alt="leftArrow" /></button>
                                                    {!play ? <button className="btn play drop-shadow" onClick={playPause} disabled={!blobURL}></button> : <button className="btn player-stop drop-shadow"></button>}
                                                    <button className="btn delete drop-shadow" onClick={deleteAudio}></button>
                                                    </>
                                                }
                                                
                                                <audio
                                                    id='player'
                                                    hidden
                                                    ref={audioPlayer}
                                                    src={blobURL}
                                                    controls="controls"
                                                    onLoadedMetadata={setTime} //fethches metadata info like duration
                                                    onTimeUpdate={setTime} //event handler whenever time progresses on player
                                                    onEnded={() => setPlay(false)} //event handler when audio has stopped playing
                                                />
                                                <a id='download' hidden href={downloadLink} download>Download</a>
                                                {/* <form onSubmit={submitData}> */}
                                                    {/* <input type="file" id="audio" name="audio" onChange={(e) => setAudio(e.target.files[0])} /> */}
                                                    {/* <button type="submit" onClick={submitData}>Upload Audio</button> */}
                                                {/* </form> */}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="form-writing d-none">
                                        <div className="heading-extend">
                                            <h3>تفاصيل إضافية<img src={images.goldArrow} className='arrow-gold' alt="goldArrow" /><span>كتابة نصية</span></h3>
                                        </div>
                                        <p>كلما كانت التفاصيل واضحه ومباشرة كلما كانت مهمة إعتماد الاجتماعات أسهل وأيسر فبالتالي وفرنا أكثر من طريقة لتقديم التفاصيل.</p>
                                        <div className="mb-3 col-md-7 text-area">
                                            <textarea className="form-control txt-area" maxLength={dataLength.text_length} id="exampleFormControlTextarea1" onChange={(e) => setText(e.target.value)}  placeholder="أكتب تفاصيل طلبك هنا.." rows="6"></textarea>
                                            {/* <input type="text" className='text-input' maxLength={28} />
                                            <span className='text-length'>{text_length}/28</span> */}
                                            <span className="txt-length">0/{dataLength.text_length}</span>
                                        </div>
                                        <span className="length-error text d-none">*الرجاء إدخال 10 أحرف على الأقل</span>
                                        <button className='btn drop-shadow txt-delete d-none p-14'></button>
                                        <button className="btn btn-black left-arrow back drop-shadow mx-3 p-14 disabled third-next" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={showOriginalForm}>التالي&emsp;<img src={images.leftArrow} alt="leftArrow" /></button>
                                    </div>

                              
                                </div>
                            </div>
                            <div className="carousel-text">
                                <p>تفاصيلك مع <span className="mr-2">إدارك</span></p>
                            </div>
                            <div className="carousel-indicators">                 
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" className="active" aria-current="true" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            </div>
                        </div>
                    </div>
                    {/* Four */}
                    <div className="carousel-item fourth animate__animated animate__fadeInLeft animate__fast" data-bs-interval="100000">
                   
                    <button className="btn btn-slider" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"><img src={images.sliderButton} alt="sliderButton" /></button>
                        <div className="container bottom-with-50">
                            <div className="row custom-hit">
                                <div className="col-md-6">
                                    <div className="text-right parallex set-position">
                                        <img src={images.calendar} className="img-fluid animate__animated animate__fadeInLeft animate__delay-1s animate__fast number" width="500" alt="calendar" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    
                                <div>
                                        <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;احجز موعدك</h3>
                                        <p className='last-original'>سيكون التواصل بشكل رسمي من خلال البريد الالكتروني قبل اعتماد موعد المكالمة الهاتفية أو الاجتماع أون لاين.</p>
                                        <p className='last-submit d-none'>مواعيد العمل الرسمية في إدراك من الأحد حتى الخميس من الساعة التاسعة صباحاً حتى الساعة الخامسة مساءً.</p>
                                        <form className="row radio-form last-original">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios7">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.cordlessSmall} className="img-fluid cordless" alt="cordless" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">مكالمة هاتفية</h6>
                                                                    <span>
                                                                    وصف الخدمة المختصر، وصف الخدمة المختصر
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios7" onChange={() => setAppointment('مكالمة هاتفية')} value="مكالمة هاتفية" />
                                                    
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="exampleRadios8">
                                                        <div className="row gx-0">
                                                            <div className="col-md-3">
                                                                <img src={images.headphonesSmall} className="img-fluid headphones" alt="headphone" />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="content">
                                                                    <h6 className="title">اجتماع اون لاين</h6>
                                                                    <span>
                                                                    وصف الخدمة المختصر، وصف الخدمة المختصر
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios8" onChange={() => setAppointment('اجتماع اون لاين')} value="اجتماع اون لاين" />
                                                    
                                                </div>
                                                <button className="btn btn-black arrow-down next p-14 disabled" type="button">اختيار&emsp;<img src={images.leftArrow} alt="leftArrow" /></button>
                                            </div>
                                        </form>
                                        <form className="row g-5 last-submit d-none">
                                            {/* <div className="col-md-6 mt-3">
                                                <label htmlFor="date" className="form-label">التاريخ</label>
                                                <input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} id="date" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor="time" className="form-label">الوقت</label>
                                                <input type="time" className="form-control" dir="rtl" value={time} onChange={(e) => setCurrentTime(e.target.value)} id="time" />
                                            </div> */}
                                            <div className="col-md-6 mt-5">
                                                <label htmlFor="datetime"  className="form-label">التاريخ</label>
                                                <Datetime value={date} closeOnSelect={ true } viewMode="days" inputProps={ inputProps } className='select-date' timeFormat={false} dateFormat="YYYY-MM-DD" onChange={setLocalDate} />
                                            </div>
                                            <div className="col-md-6 mt-5">
                                                <label htmlFor="datetime" className="form-label">الوقت</label>
                                                <Datetime type="time" closeOnSelect={ true } className='select-time' viewMode="time" inputProps={tplcaeHolder} showTime={{ use12Hours: true, format: "hh:mm A" }} dateFormat={false} onChange={setLTime} />
                                                {/* <input type="text" value={time} name="" id="" /> */}
                                            </div>
                                            <div className="col-md-12 text-at-center">
                                                <Link to="/Submitted" className="btn btn-black submit-btn p-14 d-inline-block" type="submit" onClick={handleSubmit}>ارسال</Link>
                                            </div>
                                        </form>
                                </div>
                                
                                </div>
                            </div>
                            <div className="carousel-text">
                                <p className='last-original'>حجزك مع <span className="mr-2">إدارك</span></p>
                                <p className='last-submit d-none'>موعدك مع <span className="mr-2">إدارك </span></p>
                            </div>
                            <div className="carousel-indicators">                 
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" className="active" aria-current="true" aria-label="Slide 4"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
        <div className="bg-img formBg animate__animated animate__delay-1s animate__fadeInUp"></div>
        </>
    )
}

export default AlraisatulAwal
