import $ from 'jquery';
// getting Current Year 
var date = new Date();

export function getCurrentYear() {
    var year = date.getFullYear();
   return year;
}
$(document).ready(function(){

    var i = 0;
    function move() {
        $('.hide-on-load').css('opacity', 0);
        
        if (i == 0) {
           
          i = 1;
          var elem = document.getElementById("barw");
          var widths = 1;
          var id = setInterval(frame, 10);
          function frame() {
            if (widths >= 100) {
              clearInterval(id);
              i = 0;
              $(".main-progress").fadeTo(1200, 0);
                // $('#loading').fadeOut(1000);
                $('.hide-on-load').fadeTo(4000, 1);

            } else {
                $(".main-progress").css('display', 'block')
              widths++;
              $('.progress').html(widths+"%")
              $('.progress-bar').css("width" , widths+"%");
            //   elem.style.width = widths + "%";
            
            }
          }
        }
      }

    move();
    
    // Add active class to navbar link
    $('.navbar-nav .nav-item').click(function(){

        $(this, '.nav-link').addClass('active');
        $(this).nextAll().removeClass('active');
        $(this).prevAll().removeClass('active');
        // setTimeout(() => {
          $('.footer-wrap').hide().show(900);
    })
    
    // Move Images with mouse (parallex)
    let vW = $(document).width();
    if($(window).width() > 768) {
        $(document).on("mousemove", function(event) {
            let mW = ((((event.pageX)*100)/vW).toFixed(2))-100;
            // $(".main").css('transform',	'translate(scale(1.2)');
            $(".parallex").css('transform',	'translateX('+(mW/20)+'%)');
            }).on("mouseleave", function() {
                $(".parallex").css('transform','translateX(1)');
            })
    }

    $('.navbar-nav .nav-item, .navbar-brand').click(function() {
        $('.navbar-collapse').removeClass('show');
    })
    // $(".rdtPicker").click(function() {
    //   $(this).hide();
    //   alert("fdfsdfs");
    // })
    // $(".select-date").click(function() {
    //   $(".rdtPicker").show();
    // })
    $('.recording-display').hide();
    $('.time-display').addClass('d-none');
});

function inpNum(e) {
    
  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);
  if (!charStr.match(/^[0-9]+$/))
    e.preventDefault();
    
    
}