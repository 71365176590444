import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';

const SubmittedPage = ({ images }) => {

    const [lastRecord, setLastRecord] = useState({});

    useEffect(() => {

   const LastRecord = async () =>{
       let getcon = await axios.get('https://admin.edraakcm.sa/api/requestform')
       const data = getcon.data.data;
       const last = data;
      setLastRecord(last)
        console.log(last)
    }
    LastRecord();
            
      
    const time = $('.time').text();
    const convert24hourTo12HourFormat = (time) => {
      const time_part_array = time.split(":");
      let ampm = 'AM';
      if (time_part_array[0] >= 12) {
          ampm = 'PM';
      }
      if (time_part_array[0] > 12) {
          time_part_array[0] = time_part_array[0] - 12;
      }
      const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm;

      $('.time').text(formatted_time)
    }
    
    convert24hourTo12HourFormat(userTime);
        
    }, [])
    const basic_information = localStorage.getItem("basic_information");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const city = localStorage.getItem("city");
    const book_your_appointment = localStorage.getItem("book_your_appointment");
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("phone");
    const date = localStorage.getItem("date");
    const userTime = localStorage.getItem("time");

    return (
        <section className="submitted-page animate__animated animate__fadeInLeft animate__delay-1s animate__fast">
            <Link to="/" className="btn btn-slider cross animate__animated animate__fadeInRight animate__delay-1s"><img src={images.cross} /></Link>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="text-right pt-3">
                            <img src={images.stamp} className="img-fluid animate__animated animate__fadeInLeft animate__delay-1s number" alt="stamp" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content">
                            <h3><img src={images.headingIcon} alt="headingIcon" />&nbsp;تم ارسال الطلب بنجاح</h3>
                            <div className="text-center mt-5">
                                <p className="color-silver">رقم الطلب</p>
                                <h5>{lastRecord.id}</h5>
                            </div>
                            <div className="row">
                            <div className="col-md-6 col-6">
                                        <div className="submitted-data">
                                            <h6>:نوع الاستشارة</h6>
                                            <p>{basic_information}</p>
                                            <h6>:البيانات الأساسية</h6>
                                            <p>{first_name} {last_name}</p>
                                            <p>{city}</p>
                                            <p>{phone}, {email}</p>
                                        </div>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h6>موعد الاستشارة:</h6>
                                    <p>{book_your_appointment}</p>
                                    <p>{date}</p>
                                    <p className='time'></p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
       
    )
}

export default SubmittedPage
