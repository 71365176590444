// import './App.css';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Navigation from './components/header/Navigation';
import Footer from './components/footer/Footer';
import 'jquery/dist/jquery';
// import './assets/css/responsive-style.css';
import './assets/css/style.css';
import * as scripts from './assets/js/scripts';
import { BrowserRouter as Router, Routes, Route , useLocation, Link } from 'react-router-dom';
import Home from './components/Home';
import Khidmatna from './components/Khidmatna';
// All Images
import rightArrow from './assets/icons/right_arrow.png';
import leftArrow from './assets/icons/arrow-left.svg';
import downArrow from './assets/icons/arrow-down.svg';
import brownArrow from './assets/icons/brown-arrow.png';
import building from './assets/images/building.png';
import bulb from './assets/images/bulb.png';
import bulbOff from './assets/images/bulb-off.png';
import notepad from './assets/images/notepad.png';
import notepad2 from './assets/images/notepad-2.svg';
import book  from './assets/images/book.png';
import papers from './assets/images/papers.png';
import diary from './assets/images/diary.png';
import star from './assets/images/star.png';
import clients from './assets/images/clients.png';
import airMail from './assets/images/air-mail.png';
import airPods from './assets/images/airPods.png';
import map from './assets/images/map.svg';
// import midImg1 from './assets/images/Ellipse1.png';
import midImg2 from './assets/images/Ellipse2.png';
// import midImg3 from './assets/images/Ellipse3.png';
import midImg4 from './assets/images/Ellipse4.png';
import midImg5 from './assets/images/Ellipse5.png';
import one from './assets/images/one.png';
import two from './assets/images/two.png';
import three from './assets/images/three.png';
import buildingSmall from './assets/images/building.png';
import briefcase from './assets/images/briefcase.png';
import deskLamp from './assets/images/deskLamp.png';
import fileHolder from './assets/images/file-holder.png';
import recorder from './assets/images/recorder.png';
import pencils from './assets/images/pencils.png';
import mic from './assets/icons/mic.png';
import voice from './assets/images/voice.svg';
import cordlessSmall from './assets/icons/cordless-small.png';
import headphonesSmall from './assets/icons/headphones-small.png';
import calendar from './assets/images/calendar.png';
import stamp from './assets/images/stamp.png';
import pinkBackground from './assets/images/pink-background.png';
import silverBackground from './assets/images/bg_img.png';
// import cross from './assets/icons/cross.png';
import person from './assets/images/person.png';
import maps from './assets/images/maps.png';
import sliderButton from './assets/images/slider-btn.svg';
import headingIcon from './assets/icons/heading_icon.png';
import cross from './assets/images/cross.svg';
import goldArrow from './assets/icons/gold-arrow.png';
// All Images //

import Alehkaam from './components/Alehkaam';
import Alamla from './components/Alamla';
import Atsalbana from './components/Atsalbana';
import Almutashareen from './components/Almutashareen';
import Alraisa from './components/Alraisa';
import Alraisabad from './components/Alraisabad';
import AlraisatulAwal from './components/AlraisatulAwal';
import SubmittedPage from './components/SubmittedPage';

import $ from 'jquery';

import { TransitionGroup, CSSTransition } from "react-transition-group";

function App() {

  const [allImages, setImage] = useState({rightArrow: rightArrow, leftArrow: leftArrow, building: building, bulb: bulb,
     bulbOff: bulbOff, notepad: notepad, star: star, clients: clients, airMail: airMail, airPods: airPods, book: book, map: map,
     papers: papers, midImg2:midImg2, midImg4: midImg4, midImg5:midImg5 ,diary: diary, brownArrow: brownArrow, one: one, two: two, three: three, buildingSmall: buildingSmall,
     briefcase: briefcase, deskLamp: deskLamp, fileHolder: fileHolder, recorder: recorder, pencils: pencils, downArrow: downArrow, mic: mic,
     voice: voice, cordlessSmall: cordlessSmall, headphonesSmall: headphonesSmall, calendar: calendar, stamp: stamp,
     notepad2: notepad2, cross: cross, person: person, maps: maps, sliderButton: sliderButton, headingIcon: headingIcon, goldArrow: goldArrow,
    });

    localStorage.setItem('images', JSON.stringify(allImages));
    var images = JSON.parse(localStorage.getItem('images'));

    function activePrev() {
      $('.footer-wrap').hide().show(1000);
      $('.navbar-nav').each(function(){
          // $('.nav-item.active').removeClass('active');
          $(this).find('.active').prev().addClass('active');
          $(this).find('.active').next().removeClass('active');
          // $(this).find('.nav-link.active').removeClass('active');
          // $(this).prevAll().removeClass('active');
      })

      $('.section_home').fadeOut(5000);

    }
   

    const changeBackground = (color) => {
        // if(color === 'pink') {
        //   $('.content-wrapper').css({'background-image': 'url('+pinkBackground+')'});
        // }
        // else {
        //     $('.content-wrapper').css({'background-image': 'url('+silverBackground+')'});
        // }

        if(color === 'pink') {
          $('.bg-img').css({'background': 'linear-gradient(to right, #F5EDE3 , white)'})
        }
        else {
          $('.bg-img').css({'background': 'linear-gradient(to right, silver , white)'})
        }
    }

    const AnimatedSwitch = () => {
      const location = useLocation();
      return (
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="slide" timeout={1000}>
            <Routes>
              <Route path="/Home" exact  element={ <Home  images={images} changeBackground={changeBackground} activePrev={activePrev} /> }/>
              <Route path="/Khidmatna"  element={ <Khidmatna images={images} activePrev={activePrev} /> } />
              <Route path="/Alehkaam"  element={ <Alehkaam images={images} activePrev={activePrev} /> } />
              <Route path="/Alamla"  element={ <Alamla images={images} activePrev={activePrev} /> } />
              <Route path="/Atsalbana"  element={ <Atsalbana images={images} activePrev={activePrev} /> } />
              <Route path="/Almutashareen"  element={ <Almutashareen images={images} activePrev={activePrev} /> } />
              <Route path="/"  element={ <Alraisa images={images} /> } />
              <Route path="/Alraisabad"  element={ <Alraisabad images={images} /> } />
              <Route path="/AlraisatulAwal"  element={ <AlraisatulAwal images={images} /> } />
              <Route path="/Submitted"  element={ <SubmittedPage images={images} /> } />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      );
    };

  return (
    <div className="App">
      <div className="content-wrapper">
          <Navigation changeBackground={changeBackground} />
          <AnimatedSwitch />
        <Footer getYear={scripts.getCurrentYear()} />
        
      </div>  
    </div>
  );
}

export default App;
